<template>
    <div>
        <p class="tits">Order Insight</p>
        <p class="tip">You can view your order and product data here to support your business growth.</p>
        <el-card class="box-card">
            <div style="display: flex;justify-content: space-between;align-items: center;">
                <div style="font-weight: bold;color: #000;">
                    <p>Order Overview</p>
                    <p style="font-size: 24px;">$ 2,452,144</p>
                </div>
                <div style="display: flex;align-items: center;">
                    <!-- <el-dropdown>
                        <el-button type="primary">
                            <i class="el-icon-date" style="margin-right: 5px;"></i>{{ value }}
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="item in options" :key="item.value" :value="item.value"
                                @click.native="toggle(item.value)">{{ item.value }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown> -->
                    <el-button type="primary" class="btn1"><img src="../../assets/imgs/uploads/ion_share-outline.png" alt=""
                            style="width: 15px;height: 15px;"> Export to PDF</el-button>
                </div>
            </div>
            <div id="main" style="width: 100%;height: 400px;"></div>
        </el-card>
        <el-card class="box-card" style="margin-top: 38px;">
            <div style="display: flex;justify-content: space-between;margin-bottom: 27px;">
                <div style="font-weight: bold;color: #000;">
                    <p>Order Overview</p>
                </div>
                <div style="display: flex;align-items: center;">
                    <el-date-picker v-model="value1" type="monthrange" range-separator="to" start-placeholder="开始月份"
                        end-placeholder="结束月份" @input="datechoose()" value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00', '23:59:59']">
                    </el-date-picker>
                    <el-button type="primary" class="btn1"><img src="../../assets/imgs/uploads/ion_share-outline.png" alt=""
                            style="width: 15px;height: 15px;"> Export to PDF</el-button>
                </div>
            </div>
            <el-table v-loading="loading" element-loading-spinner="el-icon-loading" :cell-style="numCellStyle"
                element-loading-background="rgba(0, 0, 0, 0.1)" :data="tableData" style="width: 100%"
                element-loading-text="loading..." :header-cell-style="{ 'text-align': 'center' }">
                <el-table-column prop="customOddNo" label="NO." width="100" align="center">
                </el-table-column>
                <el-table-column prop="imageUrl" label="" width="100" align="center">
                    <template slot-scope="scope">
                        <div style="width: 62px;height: 62px;border: 1px solid #959595;border-radius: 5px;">
                            <img v-lazy="scope.row.imageUrl" style="width: 60px;height: 60px;border-radius: 5px;" alt="">
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="Name" align="center">
                </el-table-column>
                <el-table-column prop="batch" label="Order Times" sortable width="130" align="center">
                </el-table-column>
                <el-table-column prop="num" label="Quantity" sortable width="110" align="center">
                </el-table-column>
                <el-table-column prop="price" label="Unit Price" width="110" align="center">
                </el-table-column>
                <el-table-column prop="totalAmount" label="Amount" sortable width="110" align="center">
                </el-table-column>
            </el-table>
            <div style="margin: 30px 0 33px;font-weight: bold;color: #000;text-align: center;cursor: pointer;"
                @click="showmore" v-show="isshow !== true">View More <i style="font-weight: bold;"
                    class="el-icon-arrow-down"></i></div>
        </el-card>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    data() {
        return {
            tableData: [],
            value1: [],
            num: 0,
            loading: true,
            isshow: false,
            date1: '',
            PurchaseHistory: '',
        }
    },
    created() {
        this.getdate()
        this.setDefaultDateRange()
    },
    methods: {
        getdate() {
            const currentYear = new Date().getFullYear();
            // 分配值给 options
            this.date1 = currentYear;
        },
        datechoose() {
            this.loading = true;
            this.isshow = false;
            const times = new Date(this.value1[1]);
            const year = times.getFullYear();
            const month = times.getMonth() + 1;
            // 获取该月的最后一天
            const lastDayOfMonth = new Date(year, month, 0);
            // 辅助函数用于补零
            const padZero = (num) => (num < 10 ? '0' : '') + num;
            // 格式化为 YYYY-MM-DD 00:00:00
            const formattedDate = `${lastDayOfMonth.getFullYear()}-${padZero(lastDayOfMonth.getMonth() + 1)}-${padZero(lastDayOfMonth.getDate())} 23:59:59`;
            this.value1[1] = formattedDate
            this.$axios
                .post("/api/mall/member/getProductHistoryList",
                    {
                        id: this.$store.getters.getUser.id,
                        time: this.value1
                    })
                .then(res => {
                    if (res.data.code === 200) {
                        this.tableData = res.data.data
                        this.loading = false
                    }
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
        numCellStyle({ rowIndex }) {
            if (this.isshow == false) {
                if (rowIndex > 7) {
                    return { display: "none" }
                }
            } else {
                return { display: "" }
            }
        },
        showmore() {
            this.isshow = true
        },
        setDefaultDateRange() {
            const time = new Date()
            // 调整时间到本地时区
            const lastDayOfMonth = new Date(time.getFullYear(), time.getMonth() + 1, 0);
            const padZero1 = (num) => (num < 10 ? '0' : '') + num;
            const formattedDate2 = `${lastDayOfMonth.getFullYear()}-${padZero1(lastDayOfMonth.getMonth() + 1)}-${padZero1(lastDayOfMonth.getDate())} 23:59:59`;
            const startMonth = new Date(time.getFullYear(), time.getMonth() - 6, 1);
            const padZero = (num) => (num < 10 ? '0' : '') + num;
            const formattedDate = `${startMonth.getFullYear()}-${padZero(startMonth.getMonth() + 1)}-${padZero(startMonth.getDate())} 00:00:00`;
            this.value1 = [formattedDate, formattedDate2];
        },
        getPurchaseHistory() {
            this.$axios
                .post("/api/mall/member/getPurchaseHistory",
                    {
                        id: this.$store.getters.getUser.id,
                    })
                .then(res => {
                    if (res.data.code === 200) {
                        this.PurchaseHistory = res.data.data
                    }
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        },
    },
    mounted() {
        this.datechoose()
        this.getPurchaseHistory()
        // 初始化 ECharts 图表
        var chartDom = document.getElementById('main');
        var myChart = echarts.init(chartDom);
        var option;
        option = {
            legend: {},
            tooltip: {
                trigger: 'axis',
                backgroundColor: 'rgba(50, 50, 50, 0.7)', // 背景颜色
                borderColor: '#333', // 边框颜色
                borderWidth: 1, // 边框宽度
                padding: 10, // 内边距
                textStyle: {
                    color: '#fff', // 文字颜色
                    fontSize: 14, // 字体大小
                },
                formatter: function (params) {
                    var result = '<div><strong>日期: ' + params[0].name + '</strong></div>';
                    params.forEach(function (item, index) {
                        result += '<div>' + (item.dataIndex * 3 + index + 1) + '月: ' + item.value + '</div>';
                    });
                    return result;
                }
            },
            dataset: {
                dimensions: ['product', this.date1 - 2, this.date1 - 1, this.date1],
                source: [
                    { product: 'Jan - Mar', [this.date1 - 2]: 4223.3, [this.date1 - 1]: 825.8, [this.date1]: 2193.7 },
                    { product: 'Apr - Jun', [this.date1 - 2]: 8223.1, [this.date1 - 1]: 2173.4, [this.date1]: 2155.1 },
                    { product: 'Jul - Sep ', [this.date1 - 2]: 8226.4, [this.date1 - 1]: 2165.2, [this.date1]: 2282.5 },
                    { product: 'Oct - Dec', [this.date1 - 2]: 7222.4, [this.date1 - 1]: 2153.9, [this.date1]: 8670 }
                ]
            },
            xAxis: { type: 'category' },
            yAxis: {
                min: 0,
                interval: 2000,
                axisLabel: {
                    formatter: function (value) {
                        if (value <= 8000) {
                            return value;
                        } else {
                            return value >= 10000 ? value : '';
                        }
                    }
                }
            },
            series: [
                {
                    type: 'bar',
                    itemStyle: {
                        color: '#5CA8F4'
                    },
                },
                {
                    type: 'bar',
                    itemStyle: {
                        color: '#8BCE33'
                    }
                },
                {
                    type: 'bar',
                    itemStyle: {
                        color: '#FF992C'
                    }
                },
            ]
        };
        option && myChart.setOption(option);
    },
}
</script>

<style scoped>
.tits {
    font-size: 20px;
    font-weight: bold;
}

.tip {
    margin: 20px 0 24px;
    color: #959595;
}

::v-deep .el-button--primary {
    color: #545454;
    font-size: 16px;
    background-color: #F3F3F3;
    border-color: #F3F3F3;
}

::v-deep .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #F3F3F3;
    color: #545454;
}

.btn1 {
    margin-left: 11px;
    width: 135px;
    padding: 12px 0;
    font-size: 14px;
    border-color: #FFA9A4;
    background-color: #FFE7E5;
    color: #CA141D;
}

::v-deep .el-date-editor .el-range-separator {
    width: 10%;
}

::v-deep .el-date-editor--monthrange.el-input,
.el-date-editor--monthrange.el-input__inner {
    width: 200px;
}

::v-deep .el-date-editor .el-range__close-icon {
    display: none;
}
</style>